export default
{
    "1":{
        "header":{
            "header_team": "Команда",
            "header_cost": "Цены",     
            "header_blog": "Блог",   
            "header_cab": "Личный кабинет", 
        },
        "platform":{
            "platform_h1": "Платформа для обмена сообщениями с клиентами",
            "platform_h3": "через мессенджеры и социальные сети",
            "platform_h2": "Конструктор чат-ботов",
            "platform_p": "К нам приходят, чтобы настроить общение с клиентами в мессенджерах. Разговаривайте с клиентами через WhatsApp, Telegram, Viber, Вконтакте.",
            "platform_a": "Получить доступ на 10 дней",
        },
        "example":{
            "example_h1": "Примеры внедрения мессенджеров в бизнес",
            "example_1": "Виджеты обратной связи",
            "example_2": "QR-коды",
            "example_3": "Формы для регистраций",
            "example_4": "Автоуведомления из CRM",
            "example_5": "Прямые ссылки на мессенджер",

        },
        "opportunity":{
            "opportunity_h1": "Какие возможности у мессенджеров при общении с клиентами",
            "opportunity_1": "Отправка файлов и каталогов",
            "opportunity_2": "Регистрация на мероприятия",
            "opportunity_3": "Расчет параметров",
            "opportunity_4": "Активация промокодов",
            "opportunity_5": "Генерация паролей",
            "opportunity_6": "Подтверждение бронирования",
            "opportunity_7": "Сбор отзывов и обратной связи",
            "opportunity_8": "Проведение опросов",
            "opportunity_9": "Проведение обучения",
            "opportunity_10": "Прием заказов",
            "opportunity_11": "Прием платежей",
            "opportunity_12": "Информирование и отправка уведомлений",
            "opportunity_13": "Анкетирование",
            "opportunity_14": "Ответы на частые вопросы",
            "opportunity_15": "Напоминания о записи",

            "opportunity_p1": "Отправляйте своим клиентам любые файлы. Часто клиенты просят отправить счет или каталог в мессенджер. И это действительно удобно.",
            "opportunity_p2": "Регистрировать на мероприятия можно через мессенджеры. Например, на вебинар или мастер-класс. Вам достаточно дать возможность клиенту выбрать удобный для него мессенджер.",
            "opportunity_p3": "Вы можете вести клиентов на чат-бот в мессенджеры. Например, для расчета конкретных параметров. Чат-бот проведет опрос и выдаст нужный ответ.",
            "opportunity_p4": "Теперь вы сможете раздавать промокод эффективнее. Каждый, кто захочет его активировать, попадет к вам в мессенджер, отсканировав QR-код или перейдя по ссылке.",
            "opportunity_p5": "",
            "opportunity_p6": "Уточняйте у ваших клиентов актуальность брони в автоматическом режиме.",
            "opportunity_p7": "Собирайте отзывы автоматически. В нужный момент и в удобном формате. Например, сразу после покупки.",
            "opportunity_p8": "Опрос клиента при помощи чат-бота - это очень удобно! 24/7 чат-бот будет квалифицировать ваших клиентов.",
            "opportunity_p9": "Создайте заранее весь сценарий обучения. Клиенту останется только поэтапно проходить обучение, а настроенный чат-бот поможет ему в этом.",
            "opportunity_p10": "Автоматизируйте процессы бронирования и оплаты билетов. Клиент получит билеты прямо в мессенджер.",
            "opportunity_p11": "",
            "opportunity_p12": "",
            "opportunity_p13": "",
            "opportunity_p14": "",
            "opportunity_p15": "",
        },
        "associate": {
            "associate_h1": "Общайтесь с клиентами в одном месте через удобный чат-центр",
            "associate_1": "Отправляйте файлы и каталоги",
            "associate_2": "Создавайте шаблоны для быстрых ответов",
            "associate_3": "Обменивайтесь аудиосообщениями",
            "associate_4": "Проводите опросы",
            "associate_5": "Делитесь ссылками",
            "associate_6": "Поддержка любого количества операторов и возможность общаться с клиентами с одного номера телефона или аккаунта",
            "associate_h2": "Возможна интеграция с:"
        },
        "create": {
            "create_h1": "Создавайте сценарии и автоворонки для чат-бота",
            "create_h2": "через простой и удобный редактор сценария",
            "create_1": "Отправка отложенных сообщений",
            "create_strong_1": "Например, когда нужно напомнить о себе", 
            "create_2": "Создание меню с кнопками",
            "create_strong_2": "Клиенту удобнее нажать на кнопку, чем ответить", 
            "create_3": "Автоуведомления",
            "create_strong_3": "Например, если заказ прибыл в пункт назначения", 
            "create_4": "Сбор данных",
            "create_strong_4": "Если нужно спросить клиента о чем-то конкретном", 
            "create_5": "Подключение оператора",
            "create_strong_5": "Когда чат-бот не может дать ответ, человек всегда придет на помощь", 
            "create_6": "Прием платежей",
            "create_strong_6": "Намного удобнее взять оплату прямо в мессенджере", 
        },
        "execute": {
            "execute_h1": "Запускайте умные рассылки по своей аудитории",
            "execute_h2": "с настройкой поведенческих факторов",
            "execute_p": "Например: отправить сообщение с приглашением на ужин всем, кто посетил ваш ресторан более 30 дней назад",
        },  
        "mood": {
            "mood_h1": "Настраивайте и подключайте новые каналы взаимодействия с вашими клиентами",
            "mood_h2": "вместе с Marketbot"
        },
        "team":{
            "team_h1": "Наша команда",
            "team_p": "Мы видим, что людям нравится общаться в мессенджерах. Выстраивайте связь с клиентом через диалоги, делая процесс общения максимально комфортным. Для этого мы создали платформу с помощь которой вы внедрите диалоговый маркетинг в свой бизнес.",
            "team_name_1": "Иван Каплюченко",
            "team_role_1": "Основатель проекта, CEO",
            "team_name_2": "Богдан Марфейчук",
            "team_role_2": "Технический директор, CIO",
            "team_name_3": "Владимир Казбанов",
            "team_role_3": "Инвестор проекта, CFO",
            "team_name_4": "Александр",
            "team_role_4": "Дизайнер проекта",
            "team_name_5": "Даниил",
            "team_role_5": "Маркетолог, PR",
            "team_name_6": "Раден",
            "team_role_6": "Контент мейкер",
            "team_name_7": "Анна",
            "team_role_7": "Копирайтер, SMM",
            "team_name_8": "Екатерина",
            "team_role_8": "HR специалист",
        },
        "company": {
            "company_h1": "Компании, ",
            "company_h2": "которые уже используют в своем бизнесе мессенджеры",

            "company_1_h1": "Спортмастер",
            "company_1_p": "Российская компания розничной торговли спорттоварами. Продает спорттовары в большом количестве, используя при этом различные чат-боты.",
            "company_1_span": "WhatsApp является основным каналом взаимодействия службы поддержки с клиентами.",

            "company_2_h1": "СДЭК",
            "company_2_p": "Один из самых активных и привлекательных участников российского рынка экспресс-доставки.",
            "company_2_span": "Через WhatsApp происходит уведомление и взаимодействие с клиентами.",

            "company_3_h1": "Альфа-банк",
            "company_3_p": "Крупнейший частный банк в России, входит в пятерку крупнейших банков России по объему активов.",
            "company_3_span": "Через WhatsApp происходит выдача и активация банковских карт, денежные переводы и платежи.",

            "company_4_h1": "Университет Синергия",
            "company_4_p": "Негосударственное высшее учебное заведение.",
            "company_4_span": "Через чат-бот в WhatsApp происходит обучение онлайн-курсам и регистрации на мероприятия.",

            "company_5_h1": "amoCRM",
            "company_5_p": "Удобная web-программа для анализа продаж, доступная в режиме online.",
            "company_5_span": "Через WhatsApp происходит взаимодействие службы поддержки с клиентами.",

            "company_6_h1": "Yota",
            "company_6_p": "Российский федеральный оператор беспроводной связи, предоставляющий услуги мобильной связи и 4G-интернета.",
            "company_6_span": "Через WhatsApp происходит взаимодействие службы поддержки с клиентами.",

            "company_7_h1": "БКС Банк",
            "company_7_p": "Коммерческий банк, активно развивается в розничном банковском бизнесе и обслуживании малого и среднего бизнеса.",
            "company_7_span": "Через WhatsApp происходит взаимодействие службы поддержки с клиентами.",

            "company_8_h1": "Деловые Линии",
            "company_8_p": "Крупная транспортно-логистическая компания России. Оказывает услуги по перевозке грузов.",
            "company_8_span": "Через WhatsApp происходит взаимодействие службы поддержки с клиентами.",

            "company_9_h1": "TalkBank",
            "company_9_p": "Банк в мессенджерах без отделений, мобильных приложений и колл-центров.",
            "company_9_span": "Через WhatsApp происходит выдача и активация банковских карт, денежные переводы и платежи.",

            "company_10_h1": "М.Видео",
            "company_10_p": "Крупнейшая российская розничная компания на рынке бытовой техники и электроники.",
            "company_10_span": "Через WhatsApp происходит уведомление и взаимодействие с клиентами.",

            "company_11_h1": "Эльдорадо",
            "company_11_p": "Крупный интернет-магазин электроники, цифровой и бытовой техники.",
            "company_11_span": "Через WhatsApp происходит уведомление и взаимодействие с клиентами.",

            "company_12_h1": "Nestle",
            "company_12_p": "Один из крупнейших в мире производитель продуктов питания.",
            "company_12_span": "Через WhatsApp происходит взаимодействие службы поддержки с клиентами.",

            "company_13_h1": "AliExpress",
            "company_13_p": "Глобальная виртуальная торговая площадка, предоставляющая возможность покупать товары производителей из КНР, а также России, Европы, Турции и других стран.",
            "company_13_span": "Через WhatsApp происходит уведомление и взаимодействие с клиентами.",
        },
        "setting": {
            "setting_h1": "Настройте тариф под свои задачи",
            "setting_h2": "и получите бесплатный доступ на 10 дней",
            "setting_h2_1": "Получите бесплатный доступ на 10 дней",
            "setting_step": "Шаг",
            "setting_ready": "Готово",
            "setting_phinish": "Финиш",
            "setting_after": "Далее",
            "setting_after_span": "Или нажмите",
            "setting_back": "Предыдущий шаг",

            "setting_1_h2": "Какие мессенджеры будете подключать?",
            "setting_1_h3": "Выберите один или несколько",

            "setting_2_h2": "Будете общаться с клиентами через чат-центр?",
            "setting_2_h3": "Можно пропустить",
            "setting_2_1_p": "Чат",
            "setting_2_2_p": "Написать первым",
            "setting_2_3_p": "Операторы",

            "setting_3_h2": "Включите редактор чат-ботов, если хотите автоматизировать общение с клиентами",
            "setting_3_h3": "Можно пропустить",
            "setting_3_1_p": "Редактор сценария",
            "setting_3_2_p": "Автоворонки",

            "setting_4_h2": "Выберите CRM систему для интеграции",
            "setting_4_h3": "Можно пропустить",

            "setting_5_h2": "Подключите рассылки, если планируете оповещать своих клиентов",
            "setting_5_h3": "Можно пропустить",
            "setting_5_1_p": "Массовая рассылка",

            "setting_6_h2": "Отлично! Активируйте личный кабинет и начните внедрять мессенджеры в ваш бизнес.",


            "setting_result_h1": "Итого",
            "setting_result_h1_span": "за 10 дней",

            "setting_result_h2": "Бесплатно 10 дней на",
            "setting_result_h2_span": "МАКСИМУМ",
            "setting_result_p": "В конце периода вы узнаете все возможности сервиса, и сможете настроить тариф точно под себя, чтобы не платить за лишнее.",
            "setting_result_btn": "Получить доступ",
            "setting_result_btn_span": "Для активации настройте тариф",

            "setting_popup_h1": "Для регистрации на платформе вы будете переправлены в WhatsApp, где получите логин и пароль",
            "setting_popup_p1": "Нажмите кнопку, если у вас есть приложение WhatsApp на компьютере",
            "setting_popup_p2": "Или отсканируйте QR-код с мобильного устройства",
            "setting_popup_p3": "Продолжая, вы даете согласие на обработку персональных данных и соглашаетесь с ",
            "setting_popup_p3_span_1": "политикой конфиденциальности",
            "setting_popup_p3_span_3": "и",
            "setting_popup_p3_span_2": "пользовательским соглашением",
            "setting_popup_btn": "Перейти в WhatsApp",


        },
    },




    "2":{
        "header":{
            "header_team": "Team",
            "header_cost": "Price",  
            "header_blog": "Blog", 
            "header_cab": "Personal Area",             
        },
        "platform":{
            "platform_h1": "Customer messaging platform",
            "platform_h3": "via messengers and social networks",
            "platform_h2": "Chatbot builder",
            "platform_p": "People come to us to set up communication with clients in messengers. Talk to clients via WhatsApp, Telegram, Viber, Vkontakte.",
            "platform_a": "Get access for 10 days",
        },
        "example":{
            "example_h1": "Examples of implementing instant messengers in business",
            "example_1": "Feedback widgets",
            "example_2": "QR-codes",
            "example_3": "Registration forms",
            "example_4": "Auto notifications from CRM",
            "example_5": "Direct links to the messenger",
        }, 
        "opportunity":{
            "opportunity_h1": "What are the possibilities of instant messengers when communicating with customers",
            "opportunity_1": "Uploading files and directories",
            "opportunity_2": "Registration for events",
            "opportunity_3": "Calculation of parameters",
            "opportunity_4": "Activation of promo codes",
            "opportunity_5": "Generating passwords",
            "opportunity_6": "Booking Confirmation",
            "opportunity_7": "Collecting reviews and feedback",
            "opportunity_8": "Conducting polls",
            "opportunity_9": "Conducting training",
            "opportunity_10": "Receiving orders",
            "opportunity_11": "Acceptance of payments",
            "opportunity_12": "Informing and sending notifications",
            "opportunity_13": "Questionnaire",
            "opportunity_14": "Frequently asked questions",
            "opportunity_15": "Recording reminders",

            "opportunity_p1": "Send any files to your customers. Clients often ask to send an invoice or catalog to the messenger. And it's really convenient.",
            "opportunity_p2": "You can register for events through instant messengers. For example, for a webinar or a master class. You just need to give the client the opportunity to choose a messenger that is convenient for him.",
            "opportunity_p3": "You can lead clients to the chatbot in messengers. For example, to calculate specific parameters. The chatbot will conduct a survey and give the desired answer.",
            "opportunity_p4": "Now you can distribute your promo code more efficiently. Everyone who wants to activate it will get to your messenger by scanning the QR code or following the link.",
            "opportunity_p5": "",
            "opportunity_p6": "Check with your clients about the relevance of the reservation in automatic mode.",
            "opportunity_p7": "Collect reviews automatically. At the right time and in a convenient format. For example, immediately after purchase.",
            "opportunity_p8": "A customer survey using a chat bot is very convenient! The 24/7 chatbot will qualify your customers.",
            "opportunity_p9": "Create an entire training scenario in advance. The client will only have to go through the training step by step, and the configured chat bot will help him with this.",
            "opportunity_p10": "Automate ticket booking and payment processes. The client will receive tickets directly to the messenger.",
            "opportunity_p11": "",
            "opportunity_p12": "",
            "opportunity_p13": "",
            "opportunity_p14": "",
            "opportunity_p15": "",
        },
        "associate": {
            "associate_h1": "Communicate with customers in one place through a convenient chat center",
            "associate_1": "Send files and directories",
            "associate_2": "Create templates for quick responses",
            "associate_3": "Exchange audio messages",
            "associate_4": "Conduct polls",
            "associate_5": "Share links",
            "associate_6": "Support for any number of operators and the ability to communicate with customers from one phone number or account",
            "associate_h2": "Integration with:"
        },
        "create": {
            "create_h1": "Create scripts and auto funnels for a chatbot",
            "create_h2": "through a simple and convenient script editor",
            "create_1": "Sending delayed messages",
            "create_strong_1": "For example, when you need to remind yourself", 
            "create_2": "Creating a menu with buttons",
            "create_strong_2": "It is more convenient for the client to click on the button than to answer", 
            "create_3": "Auto notifications",
            "create_strong_3": "For example, if the order has arrived at its destination", 
            "create_4": "Data collection",
            "create_strong_4": "If you need to ask a client about something specific", 
            "create_5": "Operator connection",
            "create_strong_5": "When a chatbot cannot give an answer, a person will always come to the rescue.", 
            "create_6": "Acceptance of payments",
            "create_strong_6": "It is much more convenient to take payment directly in the messenger", 
        },
        "execute": {
            "execute_h1": "Launch smart emails to your audience",
            "execute_h2": "with customizable behavioral factors",
            "execute_p": "For example: send a message inviting to dinner to everyone who visited your restaurant more than 30 days ago",
        },  
        "mood": {
            "mood_h1": "Customize and connect new channels of interaction with your customers",
            "mood_h2": "together with Marketbot"
        },
        "team":{
            "team_h1": "Our team",
            "team_p": "We see that people like to communicate in messengers. Build a connection with a client through dialogues, making the communication process as comfortable as possible. To do this, we have created a platform with which you can implement dialogue marketing in your business.",
            "team_name_1": "Ivan Kaplyuchenko",
            "team_role_1": "Founder of the project, CEO",
            "team_name_2": "Bogdan Marfeichuk",
            "team_role_2": "Technical Director, CIO",
            "team_name_3": "Vladimir Kazbanov",
            "team_role_3": "Project investor, CFO",
            "team_name_4": "Alexander",
            "team_role_4": "Project designer",
            "team_name_5": "Daniel",
            "team_role_5": "Marketer, PR",
            "team_name_6": "Raden",
            "team_role_6": "Content maker",
            "team_name_7": "Anna",
            "team_role_7": "Copywriter, SMM",
            "team_name_8": "Catherine",
            "team_role_8": "HR specialist",
        },
        "company": {
            "company_h1": "Companies ",
            "company_h2": "that already use messengers in their business",

            "company_1_h1": "Sportmaster",
            "company_1_p": "Russian sporting goods retailer. Sells sporting goods in large quantities using various chatbots.",
            "company_1_span": "WhatsApp is the primary channel for customer support.",

            "company_2_h1": "CDEK",
            "company_2_p": "One of the most active and attractive participants in the Russian express delivery market.",
            "company_2_span": "Notification and interaction with customers takes place through WhatsApp.",

            "company_3_h1": "Alfa Bank",
            "company_3_p": "The largest private bank in Russia, one of the five largest banks in Russia in terms of assets.",
            "company_3_span": "WhatsApp is used to issue and activate bank cards, money transfers and payments.",

            "company_4_h1": "University Synergy",
            "company_4_p": "Non-state higher educational institution.",
            "company_4_span": "Through the chatbot in WhatsApp, online courses and registration for events are taught.",

            "company_5_h1": "amoCRM",
            "company_5_p": "Convenient web-based program for the analysis of sales, available online.",
            "company_5_span": "Customer support interacts through WhatsApp.",

            "company_6_h1": "Yota",
            "company_6_p": "Russian federal wireless operator providing mobile and 4G Internet services.",
            "company_6_span": "Customer support interacts through WhatsApp.",

            "company_7_h1": "BCS Bank",
            "company_7_p": "Commercial bank, actively developing in retail banking business and services for small and medium-sized businesses.",
            "company_7_span": "Customer support interacts through WhatsApp.",

            "company_8_h1": "Business Line",
            "company_8_p": "Large transport and logistics company in Russia. Provides services for the transportation of goods.",
            "company_8_span": "Customer support interacts through WhatsApp.",

            "company_9_h1": "TalkBank",
            "company_9_p": "Bank in instant messengers without branches, mobile applications and call centers.",
            "company_9_span": "WhatsApp is used to issue and activate bank cards, money transfers and payments.",

            "company_10_h1": "M Video",
            "company_10_p": "The largest Russian retail company in the household appliances and electronics market.",
            "company_10_span": "Notification and interaction with customers takes place through WhatsApp.",

            "company_11_h1": "El Dorado",
            "company_11_p": "Large online store of electronics, digital and household appliances.",
            "company_11_span": "Notification and interaction with customers takes place through WhatsApp.",

            "company_12_h1": "Nestle",
            "company_12_p": "One of the world's largest food manufacturer.",
            "company_12_span": "Customer support interacts through WhatsApp.",

            "company_13_h1": "AliExpress",
            "company_13_p": "A global virtual trading platform that provides an opportunity to buy goods from manufacturers from China, as well as Russia, Europe, Turkey and other countries.",
            "company_13_span": "Notification and interaction with customers takes place through WhatsApp.",


        },
        "setting": {
            "setting_h1": "Customize the tariff for your tasks",
            "setting_h2": "and get free access for 10 days",
            "setting_h2_1": "Get free access for 10 days",
            "setting_step": "Step",
            "setting_ready": "Done",
            "setting_phinish": "Finish",
            "setting_after": "Further",
            "setting_after_span": "Or press",
            "setting_back": "Previous step",

            "setting_1_h2": "What messengers will you connect?",
            "setting_1_h3": "Choose one or more",

            "setting_2_h2": "Will you communicate through the chat-center?",
            "setting_2_h3": "You can skip",
            "setting_2_1_p": "Chat room",
            "setting_2_2_p": "Write first",
            "setting_2_3_p": "Operators",

            "setting_3_h2": "Turn on the chatbot editor if you want to automate communication with clients",
            "setting_3_1_p": "Сontinuity redactora",
            "setting_3_2_p": "Auto funnels",

            "setting_4_h2": "Choose a CRM system for integration",

            "setting_5_h2": "Connect mailing lists if you plan to notify your customers",
            "setting_5_1_p": "Wholesale mailing",

            "setting_6_h2": "Excellent! Activate your personal account and start introducing instant messengers into your business.",


            "setting_result_h1": "Total",
            "setting_result_h1_span": "in 10 days",

            "setting_result_h2": "Free 10 days for",
            "setting_result_h2_span": "MAXIMUM",
            "setting_result_p": "At the end of the period, you will find out all the features of the service, and you will be able to adjust the tariff exactly for yourself so as not to pay for too much.",
            "setting_result_btn": "Get access",
            "setting_result_btn_span": "To activate, set up a tariff",

            "setting_popup_h1": "To register on the platform, you will be redirected to WhatsApp, where you will receive a username and password.",
            "setting_popup_p1": "Click the button if you have WhatsApp on your computer",
            "setting_popup_p2": "Or scan the QR code from your mobile device",
            "setting_popup_p3": "By continuing, you consent to the processing of personal data and agree to ",
            "setting_popup_p3_span_1": "privacy policy",
            "setting_popup_p3_span_3": "and",
            "setting_popup_p3_span_2": "user agreement",
            "setting_popup_btn": "Go to WhatsApp",

        },
    }
}
