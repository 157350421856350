<template>
    <div class="team" id="team" ref="teams">
        <div class="team_intro">
            <h1>{{ langTexts[langComp].team.team_h1 }}</h1>
            <p>{{ langTexts[langComp].team.team_p }}</p>
        </div>


        <div class="team_members">
            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-1.webp" type="image/webp">
                    <img src="../assets/ava-1.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_1 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_1 }}</p>
            </div>

            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-2.webp" type="image/webp">
                    <img src="../assets/ava-2.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_2 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_2 }}</p>
            </div>


            <!--div class="team_member">
                <picture>
                    <source srcset="../assets/ava-3.webp" type="image/webp">
                    <img src="../assets/ava-3.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_3 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_3 }}</p>
            </div-->


            
        </div>

        <div class="team_members">
            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-4.webp" type="image/webp">
                    <img src="../assets/ava-4.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_4 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_4 }}</p>
            </div>

            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-5.webp" type="image/webp">
                    <img src="../assets/ava-5.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_5 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_5 }}</p>
            </div>


            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-6.webp" type="image/webp">
                    <img src="../assets/ava-6.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_6 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_6 }}</p>
            </div>

            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-7.webp" type="image/webp">
                    <img src="../assets/ava-7.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_7 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_7 }}</p>
            </div>


            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-8.webp" type="image/webp">
                    <img src="../assets/ava-8.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_8 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_8 }}</p>
            </div>
        </div>


        <div class="team_members_phone">
            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-1.webp" type="image/webp">
                    <img src="../assets/ava-1.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_1 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_1 }}</p>
            </div>

            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-2.webp" type="image/webp">
                    <img src="../assets/ava-2.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_2 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_2 }}</p>
            </div>


            <!--div class="team_member">
                <picture>
                    <source srcset="../assets/ava-3.webp" type="image/webp">
                    <img src="../assets/ava-3.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_3 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_3 }}</p>
            </div-->

            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-4.webp" type="image/webp">
                    <img src="../assets/ava-4.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_4 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_4 }}</p>
            </div>

            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-5.webp" type="image/webp">
                    <img src="../assets/ava-5.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_5 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_5 }}</p>
            </div>


            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-6.webp" type="image/webp">
                    <img src="../assets/ava-6.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_6 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_6 }}</p>
            </div>

            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-7.webp" type="image/webp">
                    <img src="../assets/ava-7.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_7 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_7 }}</p>
            </div>


            <div class="team_member">
                <picture>
                    <source srcset="../assets/ava-8.webp" type="image/webp">
                    <img src="../assets/ava-8.png" />
                </picture>
                <h2>{{ langTexts[langComp].team.team_name_8 }}</h2>
                <p>{{ langTexts[langComp].team.team_role_8 }}</p>
            </div>
        </div>



        <div class="team_background_images">
            <img class="team_background_images_1" src="../assets/back/whatsapp_back.png" alt="whatsapp_back">
            <img class="team_background_images_2" src="../assets/back/vk_back.png" alt="vk_back">

            <!--
            <img class="team_background_images_3" src="../assets/back/purplesn.png" alt="1">
            <img class="team_background_images_4" src="../assets/back/greymy.png" alt="2">
            <img class="team_background_images_5" src="../assets/back/greysn.png" alt="3">
            -->


       </div>
    </div>
</template>


<script async defer>
import langText from '../assets/lang.js'
export default {
    name: 'Team',
    data(){
        return{
            team_background_images_1_x: 1,
            team_background_images_2_x: 1,
            team_background_images_3_x: 1,
            team_background_images_4_x: 1,
            team_background_images_5_x: 1,
            langTexts: langText,
        }
    },
    methods:{
        onMouseUpdate(e){
            let x = e.pageX / window.innerWidth * 20
            //let y = e.clientY / window.innerHeight * 20
            if(window.innerWidth < 456){
                x = (window.pageYOffset) / window.innerWidth * 12
            }
            document.querySelector(".team_background_images_1").style.right = (this.team_background_images_1_x + x).toString() + 'px'
            document.querySelector(".team_background_images_2").style.left = (this.team_background_images_2_x + x).toString() + 'px'
            if(window.innerWidth > 456){
              document.querySelector(".team_background_images_1").style.left = (this.team_background_images_1_x + x).toString() + 'px'
                document.querySelector(".team_background_images_2").style.left = (this.team_background_images_2_x + x).toString() + 'px'  
            }
            /*
            document.querySelector(".team_background_images_3").style.left = (this.team_background_images_3_x + x).toString() + 'px'
            document.querySelector(".team_background_images_4").style.left = (this.team_background_images_4_x + x).toString() + 'px'
            document.querySelector(".team_background_images_5").style.left = (this.team_background_images_5_x + x).toString() + 'px'
            */

        }
    },
    computed:{
        langComp(){
            return this.$store.state.lang
        }
    },
    mounted() {
        document.addEventListener('mousemove', this.onMouseUpdate)
        if(window.innerWidth < 456){
            document.addEventListener('scroll', this.onMouseUpdate)
        }
        this.team_background_images_1_x = Number.parseInt((window.getComputedStyle(document.querySelector(".team_background_images_1")).left).substring(0, (window.getComputedStyle(document.querySelector(".team_background_images_1")).left).length - 2))
        this.team_background_images_2_x = Number.parseInt((window.getComputedStyle(document.querySelector(".team_background_images_2")).left).substring(0, (window.getComputedStyle(document.querySelector(".team_background_images_2")).left).length - 2))
        /*
        this.team_background_images_3_x = Number.parseInt((window.getComputedStyle(document.querySelector(".team_background_images_3")).left).substring(0, (window.getComputedStyle(document.querySelector(".team_background_images_3")).left).length - 2))
        this.team_background_images_4_x = Number.parseInt((window.getComputedStyle(document.querySelector(".team_background_images_4")).left).substring(0, (window.getComputedStyle(document.querySelector(".team_background_images_4")).left).length - 2))
        this.team_background_images_5_x = Number.parseInt((window.getComputedStyle(document.querySelector(".team_background_images_5")).left).substring(0, (window.getComputedStyle(document.querySelector(".team_background_images_5")).left).length - 2))
        */

    },
}
</script>


<style lang="sass">
@media screen and (min-width: 456px)
    .team
        width: 1200px
        height: 800px
        .team_intro
            width: 480px    
            margin-top: 150px
            display: inline-block
            h1
                font-size: 30px
                width: 95%
                line-height: 42px
                font-weight: 700
                color: white
                padding-bottom: 0px
                margin: 0
            p
                font-weight: 400
                font-size: 18px
                color: white
                line-height: 30px
                width: 88%
        .team_members
            display: inline-block
            position: relative
            top: -290px

            .team_member
                vertical-align: top
                text-align: center
                width: 240px
                margin-top: 50px
                display: inline-block

                h2
                    font-weight: 500
                    font-size: 20px
                    line-height: 22px
                    color: white
                p
                    font-size: 18px
                    line-height: 22px
                    font-weight: 400
                    color: #aaa
                    text-align: center
                    width: 90%
                    padding: 0 5%
                    margin: 0
                img 
                    user-select: none
        .team_members_phone
            display: none
        .team_background_images
            position: relative
            height: 0px
            user-select: none
            img
                transition: none
                position: relative
            .team_background_images_1
                top: -1025px
                left: 70px
                z-index: 3
            .team_background_images_2
                position: absolute
                top: -420px
                right: -100px
            .team_background_images_3
                top: -1110px
                left: 140px
            .team_background_images_4
                top: -1130px
                left: 210px
                z-index: 3
            .team_background_images_5
                top: -1135px
                left: 620px
                z-index: 1






@media screen and (max-width: 456px)
    .team
        height: 340vw
        //height: 1400px
        .team_intro
            width: 93%
            padding-left: 7%
            margin-top: 80px
            display: inline-block
            h1
                width: 95%
                font-size: 6.5vw
                line-height: 9.21vw
                font-weight: 700
                color: white
                padding-bottom: 0px
                margin: 0
            p
                font-weight: 400
                font-size: 3.94vw
                color: white
                line-height: 6.57vw
                width: 95%
        .team_members
            display: none
        .team_members_phone
            display: flex
            width: 100vw
            flex-wrap: wrap
            .team_member
                vertical-align: top
                text-align: center
                width: 50vw
                margin-top: 20px
                img
                    width: 80%
              

                h2
                    font-weight: 500
                    font-size: 4.38vw
                    line-height: 4.82vw
                    color: white
                    margin: 1vh 0
                p
                    font-size: 3.947vw
                    line-height: 5.7vw
                    font-weight: 400
                    color: #aaa
                    text-align: center
                    width: 90%
                    padding: 0 5%
                    margin: 0
        .team_background_images
            position: relative
            height: 0px
            width: 100%
            img
                position: relative
                display: none
            .team_background_images_1
                display: block
                position: absolute
                top: -1345px
                width: 30vw
                right: 0px
                z-index: 3
            .team_background_images_2
                position: absolute
                top: -420px
                right: -100px
            .team_background_images_3
                display: block
                top: -90px
                left: 46vw
            .team_background_images_4
                top: -1130px
                left: 210px
                z-index: 3
            .team_background_images_5
                display: block
                //top: -975px
                top: -260vw
                left: 55vw
                z-index: 1

</style>